.Footer {
    background-color: #212635;
    color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3rem 5rem;
   
}

.Footer-column {
    width: calc(25% - 5rem);
    font-weight: 300;
}


.Footer h2 {
    margin-bottom: 1rem;
    /* position:relative; */
    font-weight: 500; 
    border-bottom: 3px solid #FD2C79;
    display: inline-block;
}


.Footer p {
    color: #C9C9C9;
}

.Footer ul {
    margin: 0;
    padding: 0;
    color: #FD2C79;
    padding-left: 1rem;
}

.col-4th ul {
    list-style: none;
    padding-left: 0rem;
}

.Footer li {
    margin-bottom: 0.5rem;
}

.Footer a {
    color: #C9C9C9;
    text-decoration: none;

}

.Footer a:hover {
    text-decoration: underline;
}

.Footer ul li svg {
    margin-right: 0.5rem;

}

.Footer-bottom {
    width: 100%;
    text-align: center;
    border-top: 1px solid #c9c9c93b;
    font-weight: 300;
    margin: 0 auto;
    padding: 1rem;
    padding-bottom: 3rem;;
}

@media screen and (max-width: 768px) {
    .Footer {
        flex-direction: column;
        padding: 2rem;
    }

    .Footer-column {
        width: 100%;
        margin-bottom: 2rem;
    }

    .Footer h2::before {
        display: none;
    }

    .Footer h2::after {
        left: 0;
        width: 20%;
    }
}