.facts-and-figures {
    padding: 40px 0;
    background-color: #f5f5f5;
    text-align: center;
  }
  
  .facts-and-figures__heading {
    font-size: 32px;
    color: #333;
    margin-bottom: 30px;
  }
  
  .facts-and-figures__columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .facts-and-figures__column {
    width: 300px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin: 15px;
    text-align: center;
  }
  
  .facts-and-figures__number {
    font-size: 36px;
    color: #fff;
    background-image: linear-gradient(#FC2B7A, #ED0DBC);
    border-radius: 50%;
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  
  .facts-and-figures__title {
    font-size: 18px;
    color: #555;
    margin-bottom: 15px;
  }
  
  .facts-and-figures__description {
    font-size: 14px;
    color: #777;
  }
  
  @media (max-width: 768px) {
    .facts-and-figures__column {
      width: 100%;
      max-width: 400px;
    }
  }
  