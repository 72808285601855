.MuiTableHead-root{
    background-color: #FD2C79;
}
.MuiTableCell-head {
    color: white !important;
}
.MuiTableContainer-root{
    border-radius: 15px;
}
.MuiTableContainer-root{
    background-color: white;
}
.single-chat__form{z-index: 0;}

.MuiTableCell-root{
    padding: 12px !important;
}