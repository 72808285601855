.image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.image-grid img {
    flex: 0 0 calc(33.33% - 10px);
    height: 200px;
    margin-bottom: 20px;
}

.upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    border: 2px dashed #999;
    border-radius: 10px;
    background-color: #f5f5f5;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.upload-btn:hover {
    background-color: #ddd;
}

.upload-btn input[type="file"] {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.upload-btn span {
    margin-right: 10px;
}

.gallery-submit-btn {
    margin-top: 20px;
    background-color: #F114AB;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.gallery-submit-btn:hover {
    background-color: #cc008b;
}

.profile-submit-btn {
    margin-top: 20px;
    background-color: #F114AB;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.profile-submit-btn:hover {
    background-color: #cc008b;
}

.gallery-images{
    display: flex;
    flex-direction: column;
    margin: 1rem;
}
.gallery-delete {
    display: flex;
    justify-content: center;
    
}

.gallery-btn{
    margin-top: 20px;
    background-color: #F114AB;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}