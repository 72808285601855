.success-stories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  justify-items: center;
  margin: 0 5rem;
}

.story-heading {
  text-align: center;
  margin: 2.5rem 0;
}

.story-card img {
  width: 350px !important;
  height: 500px;
  object-fit: cover;
}

@media screen and (max-width: 460px) {
  .success-stories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
