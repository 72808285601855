.heroSection {
    display: flex;
    align-items: center;
    padding: 0rem 5rem;
    gap: 10rem;
    height: 90vh;
    background: linear-gradient(90deg, rgba(6, 6, 6, 0.667) 10%, rgba(255, 255, 255, 0) 100%), url('/src/assets/background-image-1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.mobile-image {
    display: none;
}

.mobile-image img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.heroSection-left-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.heroSection-left-column h4 {
    font-size: 1.6rem;
    font-weight: 400;
    color: white;
}

.heroSection-left-column h4 span {
    font-weight: 400;
    background: linear-gradient(90deg, #FD2C79 0%, #ED0CBD 166.45%, #ED0CBD 166.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.heroSection-left-column h1 {
    font-size: 4rem;
    font-family: 'Inria Serif';
    font-weight: 700;
    background: linear-gradient(90deg, #FD2C79 12.54%, #ED0CBD 89.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.heroSection-left-column button {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(90deg, #FD2C79 0%, #ED0CBD 100%);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 160px;
    border: none;
}

.heroSection-right-column {
    width: 50%;
}

.heroSection-right-column-box {
    background-color: white;
    padding: 1.5rem;
    border-radius: 10px;
}

.heroSection-right-column-box h2 {
    background: linear-gradient(90deg, #FD2C79 9.42%, #ED0CBD 88.74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 2.1rem;
    text-align: center;
}

.heroSection-right-column-box-form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.heroSection-right-column-box-form label {
    font-weight: 300;
    color: #FD2C79;
    margin-bottom: -13px;
    font-weight: 300;
    background-color: white;
    margin-left: 1.2rem;
}

.heroSection-right-column-box-form-field {
    font-size: 1rem;
    color: #535353;
    padding: 0.7rem 1rem;
    border-radius: 5px;
    border-color: rgba(170, 170, 170, 0.453);
}

.heroSection-right-column-box-form-submit {
    padding: 0.5rem 1.3rem;
    font-size: 15px;
    border-radius: 5px;
    letter-spacing: 0.03em;
    font-weight: 500;
    background: linear-gradient(50deg, #fd2c79, #ed0cbd);
    border: white;
    color: white;
}

@media screen and (max-width:985px) {
    .heroSection {
        flex-direction: column;
        padding: 0.5rem;
        height: 100%;
        gap: 1rem;
        background-image: none;
    }

    .mobile-image {
        display: block;
    }

    .heroSection-left-column {
        align-items: center;
    }

    .heroSection-left-column h4 {
        font-size: 1.5rem;
        text-align: center;
        color: #333;
    }

    .heroSection-left-column h1 {
        text-align: center;
        font-size: 2.2rem;
    }

    .heroSection-left-column button {
        margin: 0 auto;
    }

    .heroSection-right-column {
        width: 100%;
        padding: 0 1rem;
    }

    .heroSection-right-column-box h2 {
        font-size: 1.5rem;
    }
}
