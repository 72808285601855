/* FAQ.css */

.faq-big-container {
    margin-top: 100px;
    padding: 0 1rem;
    margin-bottom: 100px;
}

.faq-big-container h2 {
    text-align: center;
    font-size: 2rem;
    color: #444;
}

.faq-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px;
}

.faq-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.faq-column h2 {
    margin-bottom: 20px;
}

.accordion-container {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.accordion-header:hover {
    background-color: #f9f9f9;
}

.accordion-header.active {
    background-color: #eee;
}

.accordion-question {
    font-size: 1.1rem;
}

.accordion-icon {
    font-size: 1.5rem;
}

.accordion-content {
    padding: 20px;
    background-color: #f9f9f9;
    font-weight: 300;
}

@media (max-width: 768px) {
   
    .faq-big-container h2 {
        font-size: 25px;
        line-height: 1.15;
    }
    .faq-container{
        padding: 1rem 0;
    }
    .accordion-header {
        padding: 0.4rem 0.8rem;
    }
}

@media (min-width: 768px) {
   
    .faq-container {
        grid-template-columns: repeat(2, 1fr);
    }
}


