.register-form {
  max-width: 636px;
  box-shadow: 1px 1px 10px 2px #dddddd67;
  border: 1px solid rgb(253, 44, 121, 0.2);
  border-radius: 5px;
  padding: 30px;
  margin: 3rem auto;
  background-color: white;
}
.register-form__input[type="number"]::-webkit-inner-spin-button,
.register-form__input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.register-form__heading {
  font-size: 35px;
  background: linear-gradient(90deg, #fd2c79 12.54%, #ed0cbd 89.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
}

.register-form__paragraph {
  font-size: 15px;
  color: #7c7c7c;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
}

.register-form__profile-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem auto;
  gap: 2rem;
  color: #444444;
}

.register-form__input {
  width: 45%;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 20px;
  padding: 10px;
  border: 01px solid rgba(198, 198, 198, 0.467);
  color: #444444;
}

.register-form__submit-button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(90deg, #fd2c79 0%, #ed0cbd 100%);
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .register-form {
    width: 95%;
  }

  .register-form__heading {
    font-size: 25px;
  }

  .register-form__profile-image {
    gap: 1rem;
    margin: 1rem auto;
  }

  .register-form__input {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .register-form__profile-image {
    flex-direction: column;
    gap: 0.5rem;
  }
}

.flex-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col-3 {
  width: 30.5%;
}
.col-4 {
  width: 50%;
}

.prev-bg {
  background: linear-gradient(90deg, #b1b1b1 0%, #d7d4d6 100%) !important;
  color: #6c6969 !important;
}
.next-bg {
  background: linear-gradient(90deg, #2c9dfd 0%, #0c7fe1 100%) !important;
}
