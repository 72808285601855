.team-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 350px;
  }
  
  .team-member-image img {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .team-member-info h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .team-member-info p {
    font-size: 14px;
    color: #777777;
  }
  .heading-team{
    margin-top: 20px;
  }