
/* Top banner with search bar */
.banner {
    position: relative;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("https://picsum.photos/200");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.banner input[type="text"],
.banner input[type="number"],
.banner select {
    width: 200px;
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.banner input[type="date"] {
    width: 170px;
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.banner button {
    background: linear-gradient(90deg, #FD2C79 0%, #ED0CBD 100%);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.banner button:hover {
    background-color: #3e8e41;
}

/* Upcoming Sammelans section */
.upcoming {
    padding: 1.2rem 5rem;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-wrap: wrap;
}

.upcoming h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    margin-right: 20px;
}

.upcoming div {
    margin-bottom: 10px;
}

.upcoming label {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
}

.upcoming select {
    width: 150px;
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

/* Image boxes section */
.image-boxes {
    padding: 1.2rem 5rem;
    display: flex;
    /* justify-content: space-between; */
    gap: 2rem;
    align-items: center;
    flex-wrap: wrap;
}

.image-box {
    width: 30%;
    margin-bottom: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.image-box img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.image-box .content {
    padding: 2rem;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.image-box h3 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 5px;
}

.image-box p {
    font-size: 14px;
    margin: 0;
    margin-bottom:
        10px;
}
.image-boxes button{
    padding: 0.5rem 1rem;
    background-color: black;
    color: white;
}
.image-boxes button:hover{
    cursor: pointer;
    background-color: white;
    color: black;
}
/* Footer section */
.footer {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.footer p {
    margin: 0;
}

.footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.footer ul li {
    margin-right: 20px;
}

.footer ul li a {
    color: #fff;
    text-decoration: none;
}

.footer ul li a:hover {
    text-decoration: underline;
}

@media screen and (min-width:990px) {
    .container {
        padding: 1rem 5rem;
    }
}

@media screen and (max-width:790px) {
    .container {
        padding: 1rem;
    }

    .upcoming {
        padding: 0.5rem;
    }

    .image-boxes {
        padding: 0.5rem;
    }

    .image-box {
        width: 30%;
    }
}

@media screen and (max-width:470px) {
    .image-box {
        width: 100%;
    }
}
