.user-profile {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .user-profile__left {
    width: 30%;
    padding: 20px;
    margin-right: 20px;
    text-align: center;
    background-color: rgb(231, 231, 231);
  }
  
  .user-profile__image {
    width: 300px;
    height: 300px;
    object-fit: cover;
    /* border-radius: 50%; */
  }
  
  .user-profile__name {
    font-weight: bold;
    margin-top: 20px;
    font-size: 24px;
  }
  
  .user-profile__id {
    font-weight: lighter;
    margin-top: 10px;
    font-size: 16px;
    color: gray;
  }
  
  .user-profile__row {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .user-profile__row img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .user-profile__right {
    width: 70%;
    padding: 20px;
    margin-left: 20px;
  }
  
  .user-profile__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .user-profile__column {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    gap: 0.6rem;
    width: 100%;
    justify-content: space-between;
  }
  
  .user-profile__icon {
    width: 50px;
    height: 50px;
  }
  
  .user-profile__text {
    margin-top: 10px;
  }
  
  .user-profile__text--other {
    margin-top: 30px;
  }
  
  .user-profile__section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .user-profile__label {
    font-weight: bold;
    font-size: 16px;
  }
  
  .user-profile__value {
    font-size: 16px;
    color: gray;
    text-align: right;
  }
  
  .user-profile__highlight-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 10px 0;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
  
  .user-profile__highlight-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .user-profile__highlight-text {
    font-size: 16px;
    font-weight: bold;
  }
  
  .image-grid{
  display: flex;
  flex-wrap: wrap;
  justify-content: none;
  }
  
  /* Small screens (mobile devices) */
  @media only screen and (max-width: 600px) {
    .user-profile {
      flex-direction: column;
      padding: 10px;
    }
  
    .user-profile__row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: baseline;
    }
  
    .user-profile__highlight-row{
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  
    .user-profile__left {
      width: 100%;
      margin-right: 0;
    }
  
    .user-profile__image {
      width: 200px;
      height: 200px;
    }
  
    .user-profile__right {
      width: 100%;
      margin-left: 0;
    }
  
    .user-profile__column {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .user-profile__section-title {
      font-size:
      20px;
      margin-bottom: 10px;
    }
  
    .user-profile__label {
      font-size: 14px;
    }
  
    .user-profile__value {
      font-size: 14px;
    }
  
    .user-profile__highlight-text {
      margin-top: 10px;
      font-size: 14px;
    }
  
    .user-profile__highlight-row {
      margin-top: 10px;
      padding: 5px 0;
    }
  }

  .profile-avatar-info-btn {
    font-size: 1rem;
    padding: 0.6rem;
    width: 100%;
    color: #333333;
    background-color: #ffe0eb;
    border: none;
    border-radius: 5px;
  }