    /* Container */

    .container {
      max-width: 1300px;
      margin: 0 auto;
      padding: 50px;
    }

    /* Columns */

    .row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;
    }

    .col-md-4 {
      width: calc(33% - 40px);
      margin: 0 20px;
    }

    /* Icon boxes */

    .contact-icon-box {
      background-color: #f9f9f9;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 30px;
      border: 1px solid rgba(187, 187, 187, 0.486);
      height: 150px;
      display: flex;
      align-items: center;
      gap: 1rem;

    }

    .icon {
      color: white;
      background: linear-gradient(90deg, #FD2C79 0%, #ED0CBD 100%);
      width: 50px;
      height: 50px;
      padding: 0.5rem;
      font-size: 1.5rem;
      border-radius: 0.2rem;
    }

    .icon-box-content {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      color: #444;
    }

    .contact-icon-box p {
      line-height: 1.2rem;
      font-weight: 300;
      color: #999;
    }

    /* Contact form */

    .contact-form {
      background-color: #f9f9f9;
      padding: 50px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .contact-form h2 {
      text-align: center;
      margin-bottom: 30px;
      background: linear-gradient(90deg, #FD2C79 12.54%, #ED0CBD 89.19%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 28px;
    }

    .form-group {
      margin-bottom: 20px;
    }

    .form-control {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    .form-control:focus {
      border-color: #007bff;
      box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
    }

    textarea.form-control {
      resize: none;
    }

    .btn-primary {
      border: none;
      padding: 10px 20px;
      width: 100%;
      border-radius: 0.2rem;
      color: #fff;
      cursor: pointer;
      background: linear-gradient(90deg, #FD2C79 0%, #ED0CBD 100%);
      font-size: 1rem;
    }

    .btn-primary:hover {
      cursor: pointer;
      background: linear-gradient(90deg, #e10f5c 0%, #ca049f 100%);
    }

    /* Image */

    .img-fluid {
      max-width: 100%;
      height: auto;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    /* Image boxes for small screens */

    @media (max-width: 768px) {
      .col-md-4 {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;

      }

      .contact-icon-box {
        height: 100px;
        margin-bottom: 10px;
      }

      .img-fluid {
        margin-bottom: 20px;
      }
    }