.sammelan-user__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px
}

.sammelan-user__sidebar {
  width: 100%;
  max-width: 375px;
  margin-right: 20px;
  padding: 3rem 2rem;
}

.sammelan-user__search-bar,
.sammelan-user__select-option {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px
}

.sammelan-user__user-card {
  flex-grow: 1;
  max-width: 100%
}

.sammelan-user__heading {
  font-size: 24px;
  margin-bottom: 20px
}

.sammelan-user__user-data {
  display: grid;
  place-items: center;

}

.sammelan-user__user {
  display: flex;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 95%;
}

.sammelan-user__user-content {
  color: #999;
  flex-basis: 70%;
  font-size: 13px
}

.sammelan-user__user-name {
  font-size: 20px;
  color: #444
}

.sammelan-user__user-olakh-id {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600
}

.sammelan-user__user-olakh-id span {
  color: #fd2c79
}

.sammelan-user__user-avatar {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-top: 10px
}

.sammelan-user__user-image {
  flex-basis: 30%;
  margin-right: 20px
}

.sammelan-user__user-image img {
  width: 100%;
  height: auto
}

.sammelan-user__user-content__details-box-column {
  display: flex;
  flex-direction: column;

}


.sammelan-user__user-content__detail,
.sammelan-user__user-content__details-box {
  justify-content: space-between;
  display: flex
}

.sammelan-user__user-content__column {
  display: flex;
  flex-direction: column;
  width: 50%
}

.sammelan-user__user-content__detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px
}

.sammelan-user__user-interaction-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.sammelan-user__user-interaction-box {
  text-align: center;
}

.sammelan-user__user-interaction-box__view-full-profile-icon {
  color: #000000;
  font-size: 1.1rem;
}

.sammelan-user__user-interaction-box__interest-icon {
  color: #fd2c79;
  font-size: 1.1rem;
}

.sammelan-user__user-interaction-box__ignore-icon {
  color: #ed0cbd;
  font-size: 1.1rem;
}

.sammelan-user__user-interaction-box__shortlist-icon {
  color: #FF9529;
  font-size: 1.1rem;
}

.sammelan-user__user-interaction-box__report-icon {
  color: #444;
  font-size: 1.1rem;
}

.sammelan-user__user-interaction-box p {}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* gap : 6rem */
}

.pagination__item {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #f1f1f1;
  color: #333;
  cursor: pointer;
  border-radius: 5px;
}

.pagination__item:hover {
  background-color: #ddd;
}

.pagination__item.active {
  background-color: #333;
  color: #fff;
}

.sammelan-user__user-content__education-value p,
.sammelan-user__user-content__job-title-value p {
  display: flex;
}

@media only screen and (max-width:760px) {
  .sammelan-user__container {
    padding: 10px;
    gap: 10px
  }

  .sammelan-user__sidebar,
  .sammelan-user__user-card {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px
  }

  .sammelan-user__user-data {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))
  }

  .sammelan-user__user {
    flex-direction: column;
    align-items: unset;
    padding: 10px
  }

  .sammelan-user__user-image {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 10px
  }

  .sammelan-user__user-content {
    flex-basis: 100%
  }

  .sammelan-user__select-option {
    margin-bottom: 20px;
  }

  .sammelan-user__user-name {
    font-size: 18px;
  }

  .sammelan-user__user-olakh-id {
    font-size: 12px;
  }

  .sammelan-user__user-content__details-box {
    display: flex;
    flex-direction: column;
  }

  .sammelan-user__user-interaction-container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
    
  }
}

.sammelan-user__user-content__job-title-value {
  max-width: 210px;
}



.load-more-button{
  background-color: #fd2c79;
  color: White;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.load-more-button:hover {
  background-color: #ff005e;
  color: #fff;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination span {
  padding: 8px;
  margin: 0 4px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.pagination span.active {
  background-color: #ff005e;
  color: #fff;
  border-color: #ff005e;
}
