/* SingleChat.css */
/* Chat header */
.single-chat__header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  /* background-image: linear-gradient(to right, #FA2684, #EE0DBA); */
  padding: 0.5rem;
  border-radius: 0.5rem;
}

/* User avatar */
.single-chat__avatar {
  margin-right: 0.5rem;
}

/* User name */
.single-chat__user {
  margin-left: 0.5rem;
  /* color: white; */
}

/* Chat container */
.single-chat {
  width: 100%;
  padding: 1rem;
}

/* Chat header */
.single-chat__header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

/* User avatar */
.single-chat__avatar {
  margin-right: 0.5rem;
}

/* User name */
.single-chat__user {
  margin-left: 0.5rem;
}

/* Chat messages container */
.single-chat__messages {
  margin-bottom: 1rem;
  padding-right: 25px;
  padding-left: 25px;
}

/* Message bubble */
.single-chat__message-bubble {
  display: flex;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

/* User message bubble */
.single-chat__message-bubble--user {
  justify-content: flex-end;
  background-color: #dcf8c6;
}

/* Other user message bubble */
.single-chat__message-bubble--other {
  justify-content: flex-start;
  background-color: #f3f3f3;
}

/* Message content */
.single-chat__message-content {
  /* Add any additional styles for the message content here */
}

.single-chat__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Message input field and Send button */
.single-chat__input,
.single-chat__button {
  padding: 0.5rem;
  border-radius: 4px;
  outline: none;
}

/* Message input field */
.single-chat__input {
  flex-grow: 1;
  margin-right: 1rem;
  border: 1px solid #ccc;
}

/* Send button */
.single-chat__button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 100%;
}

/* Hover state for send button */
.single-chat__button:hover {
  background-color: #0056b3;
}
