.event-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: #444;
    /* padding: 0 10rem; */
    width: 50%;
    margin: 0 auto;
}

.event-title {
    text-align: center;
    font-size: 32px;
    margin-bottom: 30px;
}

.event-info {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    margin-bottom: 40px;
}

.event-date {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
}

.date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: linear-gradient(90deg, #FD2C79 0%, #ED0CBD 100%);
    color: #fff;

}

.date {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
}

.month {
    font-size: 1.2rem;
    margin: 0;
    text-align: center;
    word-wrap: break-word;
}

.event-description {
    flex-grow: 1;
    margin-left: 20px;
}

.event-heading {
    font-size: 28px;
    margin-top: 0;
}

.event-paragraph {
    color: #333;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 300;
}
/* 
.event-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.event-contact-heading {
    font-size: 28px;
    margin-bottom: 20px;
}

.contact-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
}

.contact-list li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 0.6rem;
}

.contact-list-icon {
    background: linear-gradient(90deg, #FD2C79 0%, #ED0CBD 100%);
    color: white;
    padding: 0.5rem;
    font-size: 2rem;
    border-radius: 50%;
} */

.event-notes {
    margin-top: 20px;
    line-height: 1.5;
    color: #333;
    font-size: 1rem;
    font-weight: 300;
}