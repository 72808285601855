/* Popup container */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    /* semi-transparent black */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
}

/* Popup content */
.popup-content {
    background-color: #fff;
    border-radius: 5px;
    margin: 40px 0px;
    /* max-width: 100%; */
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

/* Close button */
.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;

}


/* Style for headings and paragraphs */
.popup h2,
.popup p {
    margin: 0 0 10px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    background: linear-gradient(90deg, #FD2C79 12.54%, #ED0CBD 89.19%);
    color: white;
    width: 50px;
}

.close-button:hover {
    background-color: linear-gradient(90deg, #000000 12.54%, #000000 89.19%);
}

@media screen and (max-width:760px) {
    .popup {
        width: 100vw;
    }

    .tnc-mobile {
        padding: 0 !important;
    }
}