.login-form {
    max-width: 636px;
    box-shadow: 1px 1px 10px 2px #dddddd67;
    border: 1px solid rgba(253, 44, 121, 0.2);
    border-radius: 5px;
    padding: 30px;
    margin: 3rem auto;
    background-color: white;
  }
  
  .login-form__heading {
    font-size: 35px;
    background: linear-gradient(90deg, #fd2c79 12.54%, #ed0cbd 89.19%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
  
  .login-form__paragraph {
    font-size: 15px;
    color: #7c7c7c;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 300    ;
  }
  
  .login-form__input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-right: 20px;
    padding: 10px;
    border: 1px solid rgba(198, 198, 198, 0.467);
    color: #444444;
  }
  
  .login-form__submit-button {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    background: linear-gradient(90deg, #fd2c79 0%, #ed0cbd 100%);
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .login-form {
      width: 95%;
    }
  
    .login-form__heading {
      font-size: 25px;
    }
  
    .login-form__input {
      width: 100%;
      margin-right: 0;
    }
  }
  
  @media (max-width: 420px) {
    .login-form__profile-image {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  