th {
    text-align: left;
   background-color: #FD2C79;
   color: white;
   padding: 0.75rem 1.25rem;
}
table{
    border-spacing: 0;
    background-color: white;
   
}

td{
    padding: 0.75rem 1.25rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}
tr{
    border-radius: 20px;
}
.table-btn{
    background: #EC3E9E;
    border: none;
    color: white;
    padding: 0.1rem 0.4rem;
    border-radius: 5px;

}


