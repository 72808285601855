.testimonial-slider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 10rem;
    padding-bottom: 3rem;
    text-align: center;
    font-size: 1.3rem;
}

.testimonial-slider-content p {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 1rem;
    width: 50%;
}

.testimonial-slider {
    overflow: hidden;
}

.testimonial-slider-single {
    border: 0.2px solid rgba(180, 180, 180, 0.419);
    padding: 1rem;
    border-radius: 10px;
    height: max-content;
}

.testimonial-slider-single h3 {
    color: #333;
}

.testimonial-slider-single p {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 300;
    word-wrap: break-word;
}

.slick-slide {
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    height: 150px;
}

.slick-slide img {
    border-radius: 50%;
    width: 95px;
    height: 95px;
    border: 2px dashed #FD2C79;
    padding: 0.2rem;
}

.slick-dots {
    display: flex !important;
    justify-content: center;
}

.slick-dots li {}

.slick-dots li button {
    padding: 0.1rem;
    border: none;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.slick-dots li.slick-active button {
    background-color: #FD2C79;
    width: 20px;
}

.slick-track {
    height:450px;
    width: max-content !important;
}

@media only screen and (max-width: 1100px) {

    .testimonial-slider-content p {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .testimonial-slider-content {
        padding: 2rem 0;
        padding-bottom: 1rem;
    }

    .testimonial-slider-content p {
        width: 90%;
        font-size: .8rem;
    }

    .slick-track {
        /* height: 400px ; */
        /* transform: translate3d(-386px, 0px, 0px); */
    }

    .slick-slider .slick-track {
        /* transform: translate3d(0,0,0); */
    }

}