.mobilenav-strip-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    padding: 0.5rem 0;
    z-index: 20;

}
.mobilenav-strip-icons{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.mobilenav-strip-icons p {
    font-size: 12px;
}

.mobilenav-strip-container a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.close-sidebar-icon {
    position: absolute;
    top: 10px; /* Adjust the top position as needed */
    right: 10px; /* Adjust the right position as needed */
    cursor: pointer;
  }