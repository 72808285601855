.terms-and-conditions{
    
display: flex;
flex-direction: column;
padding: 1rem 2rem;
gap: 1rem   ;
}
.terms-and-conditions p {
font-weight: 300;
}
@media only screen and (min-width: 768px) {
    .terms-and-conditions {
        padding: 1rem 5rem;
    }
}