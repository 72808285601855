
/* align basic information and additional information to the left */
.profile-settings__basic-info-toggle,
.profile-settings__additional-info-toggle {
  text-align: left;
  background: white;
  border: none;
  font-size: 17px;
  margin-top: 1rem;
  font-weight: 600;
  color: #444444;
}

.profile-settings__basic-info-toggle:hover,
.profile-settings__additional-info-toggle:hover {
  background: none;
  cursor: pointer;
  color: #FD2C79;
}

.profile-settings__basic-info-toggle:focus,
.profile-settings__additional-info-toggle:focus {
  color: #FD2C79;
  background-color: #f7f7f7;
  outline: none;
}

/* style the input fields */
.profile-settings__basic-info-input,
.profile-settings__additional-info-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 15px;
  margin-bottom: 5px;
  color: #444444;
}

/* style the labels */
.profile-settings__basic-info-form,
.profile-settings__additional-info-form {
  border-bottom: 1px solid rgba(147, 147, 147, 0.548);
  background: white;
  padding: 1rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.profile-settings__basic-info-form label,
.profile-settings__additional-info-form label {
  display: block;
  font-weight: 600;
  color: #FD2C79;
  margin-bottom: 5px;
  font-weight: 300;
  background-color: white;
  margin-left: 1.2rem;
  margin-top: 10px;
  font-size: 14px;
}

/* flex container for two divs in a row */
.profile-settings__basic-info-flex-container,
.profile-settings__additional-info-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* style the div elements */
.profile-settings__basic-info-field,
.profile-settings__additional-info-field {
  width: calc(50% - 10px);
}

/* style the first name and last name input fields */
.profile-settings__basic-info-input--first-name,
.profile-settings__basic-info-input--last-name,
.profile-settings__additional-info-input--first-name,
.profile-settings__additional-info-input--last-name {
  width: 100%;
}

/* clear the float after the form */
.profile-settings__basic-info-form:after,
.profile-settings__additional-info-form:after {
  content: "";
  display: table;
  clear: both;
}

.profile-settings__basic-info-save-button,
.profile-settings__additional-info-save-button {
  transition: all 0.3s ease;
  border: none;
  font-size: 16px;
  padding: 10px 16px;
  border-radius: 5px;
  background: linear-gradient(50deg, #fd2c79, #ed0cbd);
  color: white;
  width: 100%;
}

.profile-settings__basic-info-save-button:hover,
.profile-settings__additional-info-save-button:hover {
  background: linear-gradient(50deg, #ed0cbd, #fd2c79);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  /* align basic information and additional information to center */
  .profile-settings__basic-info-toggle,
  .profile-settings__additional-info-toggle {
  text-align: center;
  margin-top: 0.5rem;
  }
  
  /* make the fields 100% width */
  .profile-settings__basic-info-field,
  .profile-settings__additional-info-field {
  width: 100%;
  }
  }

  .profile-settings__additional-info-input--annualIncomeExample::-webkit-outer-spin-button,
.profile-settings__additional-info-input--annualIncomeExample::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}