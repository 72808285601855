.card{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem;
    
    border-radius: 8px;
}
.card-info h2{
    color: #fd2c79;
}
.card-info p {
    font-weight: 300;
}

.card-icon{
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FD2C79;
    border-radius: 50%;
    color: white;
    font-size: 1.1rem;

}

