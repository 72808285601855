 .Membership {
    margin: 0 auto;
    max-width: 800px;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grey-text {
    background-color: #f7f7f7;
    padding: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col-md-6 {
    flex: 0 0 calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
}

.box {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.box-header {
    padding: 20px;
    color: #fff;
    text-align: center;
}

.box-body {
    padding: 20px;
    text-align: center;
}

.box-body h3 {
    font-size: 30px;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #FD2C79 0%, #ED0CBD 166.45%, #ED0CBD 166.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 500;
}

.icon-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}

.icon-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #999;
    font-weight: 300;
    border-bottom: 1px solid #c6c1c16b;

}

.icon-list li:last-child {
    border: none;
}

.box-body button {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

button:hover {
    background-color: #f1a8c0;
}

@media only screen and (max-width: 767px) {
    .col-md-6 {
        flex: 0 0 100%;
        margin-right: 0;
    }
} 