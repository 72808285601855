a {
  text-decoration: none;
}

a:hover {
  color: #4a4a4a;
}

.mobilesidebarsidebar {
  position: relative;
  flex: 1;
  background-color: #FFFFFF;
  justify-content: center;
  padding: 2rem 0rem 0rem 0rem;
  overflow: scroll;
  height: calc(100vh - 100px);
  /* height: 100vh; */
  z-index: 100;
}

.mobilesidebarprofile-avatar-info {
  display: grid;
  justify-items: center;
  padding: 1rem;
  /* padding-top: 2rem; */
}

.mobilesidebarprofile-avatar-info img {
  width: 40%;
  height: auto;
}

.mobilesidebarprofile-avatar-info h2 {
  color: #444444;
  margin: 0.1rem 0rem;
}

.mobilesidebarprofile-avatar-info h5 {
  color: #999999;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.mobilesidebarprofile-avatar-info-btn {
  font-size: 1rem;
  padding: 0.6rem;
  width: 100%;
  color: #333333;
  background-color: #FFE0EB;
  border: none;
  border-radius: 5px;
}

.mobilesidebarprofile-avatar-info-btn:hover {
  transition: 0.2s ease-out;
  background-color: #FD2C79;
  color: white;
}

/* Close button styles */
.close-button {
  font-size: 1rem;
  padding: 0.6rem;
  width: 100%;
  color: #333333;
  background-color: #FFE0EB;
  border: none;
  border-radius: 5px;
  margin-top: 1rem;
}

.close-button:hover {
  transition: 0.2s ease-out;
  background-color: #FD2C79;
  color: white;
}

/* Sidebar tabs styles */
.mobilesidebarsidebar-tabs ul {
  list-style: none;
}

.mobilesidebarsidebar-tabs li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  color: #444444;
  font-weight: 300;
  padding: 0.4rem 2rem;
  gap: 10px;
  border-bottom: 1px solid #64646408;
}

.mobilesidebarsidebar-tabs li:hover {
  padding-left: 30px;
  transition: 0.1s ease-out;
  background-color: #FFE0EB;
  padding-left: 0.5rem;
  border-left: 3px solid #FD2C79;
}

.mobilesidebarsidebar-tabs-icons {
  color: #4a4a4a;
}

.mobilesidebarsidebar-tabs li:hover .mobilesidebarsidebar-tabs-icons {
  color: #FD2C79;
}

@media screen and (max-width: 990px) {
  .mobilesidebarsidebar {
    max-width: 80%;
  }
}
