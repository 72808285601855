.story-content {
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
}

.story-card {
    max-width: min-content;
    margin-bottom: 2.5rem;
}
.story-card img{
    width: 450px;
}
@media screen and (max-width: 460px) {
    .story-image img{
        width: 300px;
    }
    .story-card{
       
    }
}




 