a {
  text-decoration: none;
}
a:hover {
  color: #4a4a4a;
}
.sidebar {
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  padding: 2rem 0rem 0rem 0rem;
}

.profile-avatar-info {
  display: grid;
  justify-items: center;
  padding: 1rem;
}

.profile-avatar-info img {
  width: 100%;
  height: auto;
}

.profile-avatar-info h2 {
  color: #444444;
  margin: 0.1rem 0rem;
}

.profile-avatar-info h5 {
  color: #999999;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.profile-avatar-info-btn {
  font-size: 1rem;
  padding: 0.6rem;
  width: 100%;
  color: #ffffff;
  background-color: #fd2c79;
  border: none;
  border-radius: 5px;
}



/* sidebar tabs */

.sidebar-tabs ul {
  list-style: none;
}

.sidebar-tabs li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  color: #444444;
  font-weight: 300;
  padding: 0.4rem 2rem;
  gap: 10px;
  border-bottom: 1px solid #64646408;
}

.sidebar-tabs li:hover {
  padding-left: 30px;
  transition: 0.1s ease-out;
  background-color: #ffe0eb;
  border-left: 3px solid #fd2c79;
}

.sidebar-tabs-icons {
  color: #4a4a4a;
}

.sidebar-tabs li:hover .sidebar-tabs-icons {
  color: #fd2c79;
}

@media screen and (max-width: 990px) {
  .sidebar {
    display: none;
  }
}

.image-container {
  position: relative;
  width: 100px; /* Set the width of the container to the image width */
  display: flex;
  justify-content: center;
}

.overlay-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Set the initial display to none */
  background-color: rgb(44 73 96 / 8%);
  width: 100%;
  height: 100%;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
}

.image-container:hover .overlay-button {
  display: flex; /* Show the overlay button on hover */
}
