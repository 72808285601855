* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    font-family: sans-serif;
    letter-spacing: 0.03em;
}


/* Navbar top bar styling */

.navbar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
    border-bottom: 1.5px solid rgb(103 103 103 / 22%);
}

.navbar-top-left-column {
    display: flex;
    gap: 1.5rem;
}

.navbar-top-left-column-icon-box-1,
.navbar-top-left-column-icon-box-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    color: #999999;
}

.navbar-top-left-column-icon-box-divider {
    border-right: 2px solid #FB2881;
    height: 30px;
    margin-left: 2rem;
}

.navbar-top-right-column {
    display: flex;
    gap: 1rem;
}

.navbar-top-right-column-social-icon {
    padding: 0.5rem;
    color: #999999;
    border-radius: 100%;
    -webkit-box-shadow: 0px 4px 4px rgba(131, 131, 131, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(131, 131, 131, 0.25);
    box-shadow: 0px 4px 4px rgba(131, 131, 131, 0.25);
}

/* Navbar bottom bar styling */

.navbar-bottom {
    padding: 0.3rem 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 5px 0px #99999936;
}

.navbar-bottom-left-column img {
    width: 200px;
    /* background: linear-gradient(50deg, #fd2c79, #ed0cbd); */
    background-color: red;
    padding: 0.5rem 1rem;
    border-radius: 3rem;
}

.navbar-bottom-middle-column {
    display: flex;
    justify-content: flex-end;
}

.navbar-bottom-middle-column-menu {
    list-style-type: none;
    display: flex;
    gap: 1rem;
}

.navbar-bottom-middle-column-menu li {}

.navbar-bottom-middle-column-menu a {
    color: #444;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
}

.navbar-bottom-middle-column-menu a:hover {
    color: #F61E95;
}

.navbar-bottom-right-column {
    display: flex;
    gap: 10px;
    align-items: center;
}

.navbar-bottom-right-column button {
    padding: 0.5rem 1.3rem;
    font-size: 15px;
    border-radius: 5px;
    letter-spacing: 0.03em;
    font-weight: 500;
}

.navbar-bottom-right-column-btn-1 {
    background: linear-gradient(50deg, #fd2c79, #ed0cbd);
    border: white;
    color: white;
}

.navbar-bottom-right-column-btn-2 {
    border: 1px solid #fd2c79;
    color: #fd2c79;
    background-color: white;
}

.navbar-bottom-right-column-notification-icon {
    color: red;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s ease;
    margin-right: 1rem;
}

.navbar-bottom-right-column-notification-icon:hover {
    color: darkred;
}

.navbar-bottom-right-column-notification-container {
    position: relative;
    display: inline-block;
}

.navbar-bottom-right-column-notification-popup {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999;
    width: 350px;
    /* Increase the width as desired */
    border-radius: 4px;
}

.notification-message {
    margin-bottom: 0.5rem;
    font-size: 16px;
    color: #333;
}

.notification-time {
    font-size: 12px;
    color: #888;
}


/* Navbar Responsive */
@media screen and (min-width:990px) {
    .navbar-bottom-right-column-hamburger {
        display: none;
    }

    .navbar-bottom-middle-column-menu {
        gap: 1rem;
    }
}

@media screen and (max-width:1200px) {
    .navbar-bottom-middle-column-menu {
        gap: 7px;
    }

    .navbar-bottom-middle-column-menu a {
        font-size: 14px;
    }
}

@media screen and (max-width:990px) {

    .navbar-top-left-column-icon-box-1 p,
    .navbar-top-left-column-icon-box-2 p {
        display: none;
    }

    .navbar-bottom-middle-column,
    .navbar-bottom-right-column {
        display: none;
    }

    .navbar-top {
        padding: 0 1rem;
    }

    .navbar-bottom {
        padding: 0.1rem 1rem;
    }
}

@media screen and (max-width:427px) {
    .navbar-top {
        padding: 0 1rem;
    }

    .navbar-top-left-column-icon-box-divider {
        margin: 0;
    }

    .navbar-top-left-column {
        gap: 0.5rem;
    }

}

/* mobile menu */
.navbar-fullscreen-menu {
    position: absolute;
    top: 125px;
    left: 0;
    width: 100%;
    height: max-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;

    z-index: 5;
}

.navbar-fullscreen-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.navbar-fullscreen-menu li {
    margin-bottom: 1rem;
}

.navbar-fullscreen-menu a {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
}


.mobile-menu-cross-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1.5rem;
    cursor: pointer;
}

.slide-in-top {
    animation: slide-in-top 0.2s ease-out;
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-70px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.navbar-fullscreen-menu li:not(:last-child) {
    border-bottom: 1px solid rgba(203, 203, 203, 0.311);
}

.navbar-bottom-right-column-notification-popup-mobile {
    left: -100px;
}


@media screen and (max-width:600px) {
    .navbar-bottom-left-column img{ 
        width: 50%;
    }
    .navbar-fullscreen-menu a{font-size: 1rem;}
}
@media screen and (max-width:990px) {
    .navbar-bottom-right-column-hamburger {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    .navbar-bottom-right-column-btn-1 {
        padding: 8px 15px;
        border-radius: 8px;
    }
}