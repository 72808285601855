.section1,
.section3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.section2,
.section4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

@media (min-width: 768px) {

    .section2,
    .section4 {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 5rem 10rem 0rem 10rem;

    }
}

@media (min-width: 768px) {

    .section1,
    .section3 {
        flex-direction: row;
        justify-content: space-between;
        padding: 5rem 10rem 0rem 10rem;

    }
}

.left-column {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .left-column {
        width: 50%;
        max-width: 45%;
        margin-bottom: 0;
        text-align: left;
    }
}

.secondary-heading {
    font-size: 2rem;
    font-weight: bold;
    line-height: 4rem;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0.5rem;
    background: linear-gradient(90deg, #FD2C79 0%, #ED0CBD 166.45%, #ED0CBD 166.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.paragraph {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: 2rem;
    text-align: justify;
}

.right-column {
    width: 100%;
}

@media (min-width: 768px) {
    .right-column {
        max-width: 35%;
    }
}

.image {
    width: 100%;
    height: auto;
    border-radius: 2rem;
}