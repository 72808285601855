* {
    margin: 0;
    padding: 0;
}

/* About Section Start  */
.aboutSection {
    padding: 3rem 5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center
}

.aboutSection-left-column {
    display: flex;
    justify-content: center
}

.aboutSection-left-column img {
    width: 80%
}

.aboutSection-right-column {
    display: flex;
    flex-direction: column;
    gap: 1.5rem
}

.aboutSection-right-column h2 {
    font-size: 2rem;
    color: #444
}

.aboutSection-right-column p {
    color: #707070;
    font-weight: 300
}

.aboutSection-right-column-about-lists {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    gap: 1rem
}

.aboutSection-right-column-about-lists-icon {
    color: #fd2c79;
    height: 35px;
    width: 35px
}

.aboutSection-right-column-about-lists li {
    display: flex;
    align-items: center;
    gap: 1rem
}

.aboutSection-right-column-about-lists li svg {
    font-size: 1.5rem;
    color: 444
}

.aboutSection-right-column-about-lists li h5 {
    font-size: 1.2rem;
    color: #444
}

.aboutSection-right-column button {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    background: linear-gradient(90deg, #fd2c79 0, #ed0cbd 100%);
    border-radius: 5px;
    padding: .5rem 1rem;
    width: 160px;
    border: none
}

@media only screen and (max-width:768px) {
    .aboutSection {
        padding: 2rem;
        grid-template-columns: 1fr
    }

    .aboutSection-left-column {
        display: flex;
        justify-content: center
    }

    .aboutSection-left-column img {
        width: 100%;
        max-width: 300px
    }

    .aboutSection-right-column {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
        text-align: center
    }

    .aboutSection-right-column h2 {
        font-size: 1.8rem
    }

    .aboutSection-right-column p {
        font-size: 1rem
    }

    .aboutSection-right-column-about-lists {
        display: grid;
        grid-template-columns: 1fr;
        list-style: none;
        gap: 1rem
    }

    .aboutSection-right-column-about-lists li {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: .9rem
    }

    .aboutSection-right-column-about-lists li svg {
        font-size: 1.2rem
    }

    .aboutSection-right-column-about-lists li h5 {
        font-size: 1rem
    }

    .aboutSection-right-column button {
        width: 100%
    }
}

/* About Section End  */

/* Featured Profiles Start */

.featuredSection {
    text-align: center;
    padding: 0 3rem
}
.featuredSection h2{
font-size: 32px;
color: #444;
}
.featuredSection-profiles {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem
}

@media screen and (max-width:1495px) {
    .featuredSection-profiles {
        grid-template-columns: repeat(5, 1fr)
    }
}

@media screen and (max-width:1266px) {
    .featuredSection-profiles {
        grid-template-columns: repeat(4, 1fr)
    }
}

@media screen and (max-width:1048px) {
    .featuredSection-profiles {
        grid-template-columns: repeat(3, 1fr)
    }
}

@media screen and (max-width:800px) {
    .featuredSection {
        padding: 0 1rem
    }

    .featuredSection-profiles {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem
    }
}

/* Featured Profiles End */


/* How It Work start */

.howItWork {
    color: #fff;
    padding: 10rem 2rem;
    margin-top: 5rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background: url("");
    background: linear-gradient(90deg, rgba(6, 6, 6, .638) 100%, rgba(255, 255, 255, 0) 100%), url(/src/assets/success-story-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
}

.howItWork-content {
    display: flex;
    flex-direction: column;
    align-items: center
}

.howItWork-content h2 {
    font-size: 2rem
}

.howItWork-content p {
    max-width: 80%;
    text-align: center;
    font-weight: 300;
    font-size: .9rem
}

.howItWork-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem
}

.howItWork-box img {
    width: 85px
}

.howItWork-box h5 {
    font-size: 1.3rem
}

.howItWork-box p {
    font-size: .9rem;
    font-weight: 300
}

.howItWork-box-1,
.howItWork-box-2,
.howItWork-box-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    text-align: center;
}

@media screen and (min-width:992px) {
    .howItWork-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%
    }

    .howItWork-box>div {
        width: 30%
    }
}

@media screen and (max-width:1290px) {
    .howItWork-content p {
        max-width: 70%
    }
}

@media screen and (max-width:1000px) {
    .howItWork {
        padding: 3rem 1rem
    }

    .howItWork-box {
        gap: 1rem
    }

    .howItWork-box img {
        width: 70px
    }

    .howItWork-box h5 {
        font-size: 1rem
    }

    .howItWork-box p {
        font-size: .8rem
    }
}

@media screen and (max-width:600px) {
    .howItWork-content h2 {
        font-size: 1.5rem
    }

    .howItWork-content p {
        max-width: 100%
    }

    .howItWork-box {
        gap: 1rem
    }

    .howItWork-box img {
        width: 60px
    }

    .howItWork-box h5 {
        font-size: 1rem
    }

    .howItWork-box p {
        font-size: .8rem
    }
}

/* How It Work end */


/* Success Stories Start */
.gallery {
    padding: 2rem 1rem;
    gap: .5rem;
    background-color: #111723;
    display: flex;
    flex-direction: column;
    align-items: center; /* Added to center align the content */
}

.gallery-content {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
}

.gallery-content h2 {
    font-size: 1.5rem;
}

.gallery-content p {
    text-align: center;
    font-weight: 300;
    font-size: .8rem;
}

.gallery .gallery-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Added to center align the items */    gap: 1rem;
}

.gallery .gallery-row-item {
    flex: 0 0 23%; /* Added to make each item take 25% width */
    display: flex;
    justify-content: center; /* Added to center align the item content */
}

.gallery .gallery-row-item img {
    width: 100%;
    /* height: 300px; */
    object-fit: cover;
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .gallery {
        padding: 2rem;
    }

    .gallery-content h2 {
        font-size: 1.5rem;
    }

    .gallery-content p {
        font-size: .8rem;
    }

    .gallery-row {
        flex-direction: row;
    }
}

@media screen and (min-width: 1025px) {
    .gallery {
        padding: 3rem 5rem;
    }

    .gallery-content h2 {
        font-size: 2rem;
    }

    .gallery-content p {
        font-size: .9rem;
    }

    .gallery-row {
        flex-direction: row;
    }
}


/* Success Stories End */


/* Features and Policy Start */

.FeaturesPolicy {
    padding: 5rem 10rem;
    background: linear-gradient(90deg, rgba(6, 6, 6, .638) 100%, rgba(255, 255, 255, 0) 100%), url(/src/assets/success-story-6.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.FeaturesPolicy-header {
    text-align: center;
    margin-bottom: 3rem;
    color: #fff
}

.FeaturesPolicy-title {
    font-size: 2rem
}

.FeaturesPolicy-desc {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 1rem
}

.FeaturesPolicy-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-template-rows: repeat(2, 1fr); */
    gap: 3rem
}

.FeaturesPolicy-grid-item {
    background-color: #f5f5f5;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 1rem
}

.FeaturesPolicy-grid-item-image-div {
    background-color: #fff;
    border-radius: 100%;
    width: 100px;
    height: 100px
}

.FeaturesPolicy-grid-item img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    padding: 1rem
}

.FeaturesPolicy-grid-item-desc {
    font-weight: 300
}

@media only screen and (max-width:1024px) {
    .FeaturesPolicy {
        padding: 3rem 5rem
    }

    .FeaturesPolicy-header {
        margin-bottom: 2rem
    }

    .FeaturesPolicy-title {
        font-size: 1.5rem
    }

    .FeaturesPolicy-desc {
        font-size: .8rem
    }

    .FeaturesPolicy-grid-container {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 2rem
    }

    .FeaturesPolicy-grid-item {
        border-radius: .5rem;
        padding: 1rem
    }

    .FeaturesPolicy-grid-item-image-div {
        background-color: #fff;
        border-radius: 100%;
        width: 80px;
        height: 80px
    }

    .FeaturesPolicy-grid-item img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        padding: .5rem
    }

    .FeaturesPolicy-grid-item-desc {
        font-size: .7rem;
        margin-top: .5rem
    }
}

@media only screen and (max-width:768px) {
    .FeaturesPolicy {
        padding: 1rem
    }

    .FeaturesPolicy-header {
        margin-bottom: 2rem
    }

    .FeaturesPolicy-title {
        font-size: 1.5rem
    }

    .FeaturesPolicy-desc {
        font-size: .8rem
    }

    .FeaturesPolicy-grid-container {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(6, 1fr);
        gap: 2rem
    }

    .FeaturesPolicy-grid-item {
        border-radius: .5rem;
        padding: 1rem
    }

    .FeaturesPolicy-grid-item-image-div {
        background-color: #fff;
        border-radius: 100%;
        width: 60px;
        height: 60px
    }

    .FeaturesPolicy-grid-item img {
        width: 60px;
        height: 60px;
        object-fit: contain;
        padding: .5rem
    }

    .FeaturesPolicy-grid-item-desc {
        font-size: .7rem;
        margin-top: .5rem
    }
}

/* Features and Policy End */
.mobileApp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 2rem;
    padding: 3rem 5rem;
  }
  
  .mobileApp-left-column {
    display: flex;
    flex-direction: column;
  }
  
  .mobileApp-heading {
    font-size: 2rem;
    color: #444;
  }
  
  .mobileApp-paragraph {
    color: #707070;
    font-weight: 300;
    margin-top: 1rem;
  }
  
  .mobileApp-playStoreLink {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    background: linear-gradient(90deg, #fd2c79 0, #ed0cbd 100%);
    border-radius: 5px;
    padding: .5rem 2rem;
    width: 350px;
    border: none;
    text-decoration: none;
    display: inline-block;
  }
  
  .mobileApp-image {
    width: 100%;
  }
  
  @media only screen and (max-width: 768px) {
    .mobileApp {
      grid-template-columns: 1fr;
      padding: 2rem;
    }
  
    .mobileApp-left-column {
      text-align: center;
    }
  
    .mobileApp-image {
      margin-top: 2rem;
    }
  }
  