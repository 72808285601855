.redirect-upgrade-button{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(90deg, #FD2C79 0%, #ED0CBD 100%);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 160px;
    border: none;
}
.redirect-upgrade-heading{
    margin-bottom: 1rem;
}
