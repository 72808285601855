.main-container {
    display: flex;
    padding: 2rem 5rem;
    gap: 1.5rem;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 3;
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 1.5rem;
    margin-bottom: 1.5rem;

}

.card-1 {
    background-color: #F9DBE7;
    border-left: 3px solid #fd2c79;
}

.card-2 {
    background-color: #FFEFCE;
    border-left: 3px solid #FF9E42;
}

.card-3 {
    background-color: #DCFCE7;
    border-left: 3px solid #3BD855;
}

.card-4 {
    background-color: #F3E8FF;
    border-left: 3px solid #C085FF;
}

.card-5 {
    background-color: #F9DBE7;
    border-left: 3px solid #FD2C79;
}

.card-6 {
    background-color: #FFEFCE;
    border-left: 3px solid #FF9E42;
}

/* current package */
.current-package {
    background-color: white;
    padding: 1rem 2rem;
}

.current-package-info {
    background-color: white;
    padding: 2rem 2rem 1rem 2rem;
}

.icon-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.8rem;
}

.icon-box p {
    font-weight: 300;
    color: #9B9B9B;
}

.current-package-info-p {
    margin-top: 10px;
    color: #9B9B9B;
    font-weight: 300;
}

.gradient-btn {
    margin-top: 0.8rem;
    padding: 0.6rem;
    background: rgb(253,44,121);
    background: linear-gradient(90deg, rgba(253,44,121,1) 35%, rgba(227,47,185,1) 88%);
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    border-radius: 5px;
    transition: background 2s ease;
}

.gradient-btn:hover {
    cursor: pointer;
    background: linear-gradient(90deg, rgba(227,47,185,1) 35%, rgba(253,44,121,1) 88%);
}

@media screen and (max-width : 550px) {
    .main-container {
        padding : 0;
    }
    .container {
        padding: 0 0.5rem !important;
    }
}
