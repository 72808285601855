.search-result__container {
    display: flex;
    /* justify-content: center; */
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 20px;
    gap: 20px
  }
  
  .search-result__sidebar {
    width: 100%;
    max-width: 375px;
    margin-right: 20px;
    padding: 3rem 2rem;
  }
  
  .search-result__search-bar,
  .search-result__select-option {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px
  }
  
  .search-result__user-card {
    flex-grow: 1;
    max-width: 100%
  }
  
  .search-result__heading {
    font-size: 24px;
    margin-bottom: 20px
  }
  
  .search-result__user-data {
    display: grid
  }
  
  .search-result__user {
    display: flex;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
  
    width: 100% !important;
  }
  
  .search-result__user-content {
    color: #999;
    flex-basis: 70%;
    font-size: 13px
  }
  
  .search-result__user-name {
    font-size: 20px;
    color: #444
  }
  
  .search-result__user-olakh-id {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 600
  }
  
  .search-result__user-olakh-id span {
    color: #fd2c79
  }
  
  .search-result__user-avatar {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-top: 10px
  }
  
  .search-result__user-image {
    flex-basis: 30%;
    margin-right: 20px
  }
  
  .search-result__user-image img {
    width: 100%;
    height: auto
  }
  
  .search-result__user-content__details-box-column {
    display: flex;
    flex-direction: column;
  
  }
  
  
  .search-result__user-content__detail,
  .search-result__user-content__details-box {
    justify-content: space-between;
    display: flex
  }
  
  .search-result__user-content__column {
    display: flex;
    flex-direction: column;
    width: 50%
  }
  
  .search-result__user-content__detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px
  }
  
  .search-result__user-interaction-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .search-result__user-interaction-box {
    text-align: center;
  }
  
  .search-result__user-interaction-box__interest-icon {
    color: #fd2c79;
    font-size: 1.1rem;
  }
  
  .search-result__user-interaction-box__ignore-icon {
    color: #ed0cbd;
    font-size: 1.1rem;
  }
  
  .search-result__user-interaction-box__shortlist-icon {
    color: #FF9529;
    font-size: 1.1rem;
  }
  
  .search-result__user-interaction-box__report-icon {
    color: #444;
    font-size: 1.1rem;
  }
  
  .search-result__user-interaction-box p {}
  
  .member-filter-container {
    position: relative;
  }
  
  .search-result__container button {
    display: none;
  }
  
  .search-result__desktop-head {
    display: inline;
  }
  
  .search-result__mobile-head {
    display: none;
  }
  
  .search-result__user-content__education-value p, 
  .search-result__user-content__job-title-value p {
    display: flex;
  }
  
  
  @media only screen and (max-width:760px) {
    .search-result__mobile-head {
      display: inline;
    }
  
    .search-result__desktop-head {
      display: none;
    }
  
    .search-result__container {
      display: flex;
      align-items: center !important;
      justify-content: flex-start !important;
      padding: 1rem !important;
    }
  
    .search-result__container button {
      display: block;
      background-color: #333;
      color: white;
      padding: .5rem 1rem;
    }
  
    .search-result__container button:hover {
      background-color: #444;
    }
  
    .search-result__sidebar {
      padding: 0 !important;
    }
  
    .search-result__sidebar,
    .search-result__user-card {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .search-result__user-data {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))
    }
  
    .search-result__user {
      flex-direction: column;
      align-items: unset;
      padding: 10px;
      width: 100% !important;
    }
  
    .search-result__user-image {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 10px
    }
  
    .search-result__user-content {
      flex-basis: 100%
    }
  
    .search-result__select-option {
      margin-bottom: 20px;
    }
  
    .search-result__user-name {
      font-size: 18px;
    }
  
    .search-result__user-olakh-id {
      font-size: 12px;
    }
  
    .search-result__user-content__details-box {
      display: flex;
      flex-direction: column;
    }
  
    .search-result__sidebar {
      width: 100%;
      max-width: 375px;
      margin-right: 20px;
      padding: 1rem;
    }
  
    .search-result__sidebar {
      display: none;
    }
  
    .search-result__sidebar.open {
      display: block;
    }
    .search-result__user-interaction-container{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: 1rem;
      justify-content: center;
    justify-items: center;
    }
  
  }
  
  @media only screen and (min-width: 768px) {
    .search-result__filter-button {
      display: none;
    }
  }