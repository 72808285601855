.singleFeaturedCard {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 1rem;
  background: linear-gradient(90deg, rgba(253, 44, 121, .15) 0, rgba(237, 12, 189, .15) 100%);
  gap: .8rem;
  margin-top: 5.5rem;
  justify-content: center;
  width: 100%
}

.singleFeaturedCard img {
  align-self: center;
  width: 133px;
  border-radius: 1.5rem;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  margin-top: -5.5rem
}

.singleFeaturedCard-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center
}

.singleFeaturedCard-col strong {
  font-size: .8rem
}

.singleFeaturedCard-col p {
  font-size: .8rem
}

.singleFeaturedCard-button {
  display: flex;
  justify-content: center;
}

.singleFeaturedCard-button button {
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  padding: 0.5rem 3.5rem;
}

.singleFeaturedCard-button-gradient {
  color: #fff;
  background: linear-gradient(90deg, #fd2c79 0, #ed0cbd 100%);
  border: none;

}

.singleFeaturedCard-button-normal {
  color: #444;
  border: 1px solid grey;
  text-align: center;

}

@media only screen and (max-width:768px) {
  .singleFeaturedCard {
    margin-top: 3rem
  }

  .singleFeaturedCard img {
    margin-top: -3rem;
    width: 100%;
    max-width: 200px;
    height: auto
  }

  .singleFeaturedCard-row {
    flex-direction: column;
    align-items: center
  }

  .singleFeaturedCard-col {
    text-align: center
  }

  .singleFeaturedCard-col p,
  .singleFeaturedCard-col strong {
    font-size: .9rem
  }

  .singleFeaturedCard-button {
    flex-direction: column;
    gap: .5rem
  }

  .singleFeaturedCard-button button {
    width: 100%;
    padding: 0.5rem;
  }
}